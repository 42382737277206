import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import Urls from "./utils/Urls";

const noImage = require("./image-not-found-scaled.png");

function GroupRegistration(props: any) {
  let groupRegistration = props.groupRegistration;

  const save = (number: Number) => {
    //setOpen(true)

    let body: any = {
      rating: { group_registration_id: groupRegistration.id, value: number },
    };
    let headers: any = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.token || "",
      },
    };

    axios
      .post(Urls.RATINGS, body, headers)
      .then(() => {
        // Done
      })
      .catch(() => alert("Fehler"));

    props.onCallback(number);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <img
          alt={groupRegistration.number}
          style={{ position: "absolute", top: 70, left: 0, height: "50%" }}
          src={`https://files.gray-connect.de/2023/${groupRegistration.number}.JPG`}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          mt: 2,
          position: "absolute",
          bottom: 80,
          width: "100%",
          left: -10,
        }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
          <Button
            key={number}
            sx={{ m: "5px", width: "calc(20% - 10px)", fontSize: 40 }}
            color={groupRegistration.rating === number ? "success" : "warning"}
            onClick={() => save(number)}
            variant="contained">
            {number}
          </Button>
        ))}
      </Grid>

      <Grid
        item
        xs={12}
        style={{ position: "absolute", bottom: 10, width: "100%", left: -10 }}>
        <Button
          sx={{ width: "100%", mt: 4, backgroundColor: "gray", fontSize: 30 }}
          size="large"
          onClick={() => props.onCallback(undefined)}
          variant="contained"
          color="info">
          Zurück
        </Button>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default GroupRegistration;
