import { useEffect, useState } from "react"
import axios from 'axios';
import Urls from "./utils/Urls";
import { Grid } from "@mui/material";

export default function Presentation() {
  var timeout: any = undefined;

  const [groupRegistration, setGroupRegistration] = useState<any>();
  const [url, setUrl] = useState<string>("")

  function loadData(g: any){
    const bearer = localStorage.getItem("token")
    const id = parseInt(localStorage.getItem("id") || "0")

    if(bearer){
      axios.get(Urls.PRESENTATION, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', "Authorization": bearer } })
      .then((res) => {
        if(res.data.id !== id) {
          console.log("NEU")
          setGroupRegistration(res.data)
          localStorage.setItem("id", res.data.id)
          setUrl(res.data.url)
        }
      } )
      .catch((e) => {
        console.log("Unauthorized")
      })
    } else {
      console.log("No Bearer")
    }
  }

  function loadGroup(g: any){
    timeout = setTimeout(() => {

      loadData(g);

      loadGroup(g);
    }, 3000)
  }

  useEffect(() => {
    localStorage.removeItem("id")

    loadData(groupRegistration)
    loadGroup(groupRegistration)

    return () => {
      clearTimeout(timeout)
      localStorage.removeItem("id")
    }
  }, [])


  return <Grid container style={{ height: window.innerHeight - 60, backgroundColor: 'black' }}>
    { url && <img src={url} style={{ width: '100%', maxHeight: window.innerHeight - 100, objectFit: 'contain', objectPosition: 'center center'}} /> }
  </Grid>
}