/**
 * Urls to Server
 */
const Urls = {
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  LOGIN: `${process.env.REACT_APP_SERVER_URL}/login`,
  RATINGS: `${process.env.REACT_APP_SERVER_URL}/api/v1/ratings`,
  GROUP_REGISTRATIONS: `${process.env.REACT_APP_SERVER_URL}/api/v1/group_registrations`,
  PRESENTATION: `${process.env.REACT_APP_SERVER_URL}/api/v1/presentation`,
  REGISTRATION: `${process.env.REACT_APP_SERVER_URL}/api/v1/price_registrations`,
};
export default Urls;
