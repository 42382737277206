import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import Urls from "./utils/Urls";

function SignIn(props: any) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const login = () => {
    axios
      .post(
        Urls.LOGIN,
        { admin_user: { email, password } },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          console.log(response.headers.authorization);
          localStorage.setItem("name", email);
          localStorage.setItem("token", response.headers.authorization);
          window.location.reload();
        } else {
          console.log("Unauthorized");
        }
      });
  };

  return (
    <Box
      component="form"
      sx={{
        p: 2,
        "& .MuiTextField-root": { mt: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off">
      <Typography
        variant="h5"
        component="h1"
        sx={{ width: "100%", textAlign: "center", mt: 5, mb: 5 }}>
        Login
      </Typography>
      <div>
        <TextField
          required
          type="email"
          id="outlined-required"
          label="E-Mail"
          onChange={(e) => setEmail(e.target.value || "")}
          value={email}
        />

        <TextField
          type="password"
          required
          id="outlined-required"
          label="Password"
          onChange={(e) => setPassword(e.target.value || "")}
          value={password}
        />

        <Button
          variant="contained"
          sx={{ mt: 2, width: "100%" }}
          onClick={login}>
          Login
        </Button>
      </div>
    </Box>
  );
}

export default SignIn;
