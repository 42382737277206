import { useEffect, useRef, useState } from "react";
import "./App.css";
import axios from "axios";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import Urls from "./utils/Urls";

export default function Registration() {
  const [groupRegistrations, setGroupRegistrations] = useState<any[]>([]);

  useEffect(() => {
    reloadGroupRegistrations();
  }, []);

  const reloadGroupRegistrations = () => {
    const bearer = localStorage.getItem("token");

    if (bearer) {
      //setLoading(true)
      axios
        .get(Urls.REGISTRATION, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: bearer,
          },
        })
        .then((res) => {
          setGroupRegistrations(res.data);
        })
        .catch((e) => {
          console.log("Unauthorized");
        });
    } else {
      console.log("No Bearer");
    }
  };

  const onCallback = (id: number) => {
    const bearer = localStorage.getItem("token");

    axios
      .put(
        `${Urls.REGISTRATION}/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: bearer!,
          },
        }
      )
      .then((response) => {
        console.log(response);
        reloadGroupRegistrations();
      })
      .catch((e) => alert(e));
  };

  const _0Ref = useRef(null);
  const _50Ref = useRef(null);
  const _100Ref = useRef(null);
  const _150Ref = useRef(null);
  const _200Ref = useRef(null);

  // @ts-ignore
  const executeScroll = (ref: any) => ref?.current?.scrollIntoView();

  const renderGroupRegistration = (g: any) => {
    var ref = _0Ref;

    if (g.number === 50) {
      ref = _50Ref;
    } else if (g.number === 100) {
      ref = _100Ref;
    } else if (g.number === 150) {
      ref = _150Ref;
    } else if (g.number === 200) {
      ref = _200Ref;
    }

    return (
      <>
        {(g.number % 50 === 0 || g.number === 25) && (
          <Typography
            variant="h4"
            key={g.id}
            component="h1"
            sx={{ width: "100%", m: 2 }}
            ref={ref}>
            {g.number}
          </Typography>
        )}
        <Grid item xs={3} key={g.number}>
          <Button
            variant="contained"
            color={Boolean(g.registered) ? "success" : "warning"}
            size="large"
            sx={{ width: "100%", fontSize: 30 }}
            onClick={() => onCallback(g.id)}>
            <b>{g.number}</b>
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Grid container sx={{ p: 1 }} direction="row" spacing={2}>
      <Grid item xs={10}>
        <Grid container spacing={1}>
          {groupRegistrations.map((g) => renderGroupRegistration(g))}
        </Grid>
      </Grid>

      <Grid item xs={2} sx={{ position: "fixed", to: 70, right: 10 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color={"info"}
              size="large"
              sx={{ width: "100%", fontSize: 30 }}
              onClick={() => {
                executeScroll(_0Ref);
              }}>
              0-50
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color={"info"}
              size="large"
              sx={{ width: "100%", fontSize: 30 }}
              onClick={() => {
                executeScroll(_50Ref);
              }}>
              50-100
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color={"info"}
              size="large"
              sx={{ width: "100%", fontSize: 30 }}
              onClick={() => {
                executeScroll(_100Ref);
              }}>
              100-150
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color={"info"}
              size="large"
              sx={{ width: "100%", fontSize: 30 }}
              onClick={() => {
                executeScroll(_150Ref);
              }}>
              150-200
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color={"info"}
              size="large"
              sx={{ width: "100%", fontSize: 30 }}
              onClick={() => {
                executeScroll(_200Ref);
              }}>
              200-250
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
