import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';

export default function Navbar(props: any) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            { props.name }
          </Typography>
          { false && !props.presentation && <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            { localStorage.getItem("name") }
          </Typography> }

          { props.state !== 2 && <Button variant="contained" color="success" onClick={() => props.setState(2)}>Registrierung</Button> }
          
          { props.state !== 1 && <Button variant="contained" color="success" onClick={() => props.setState(1)}>Presentation</Button> }
          { props.state !== 0 && <Button variant="contained" color="success" onClick={() => props.setState(0)}>Beenden</Button> }
          { props.loading && <CircularProgress color="secondary" /> }
          <Button variant="contained" color="secondary" onClick={() => {
            localStorage.removeItem("token"); window.location.reload();
          }}>Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}