import { useEffect, useRef, useState } from "react";
import "./App.css";
import axios from "axios";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import GroupRegistration from "./GroupRegistration";
import Urls from "./utils/Urls";
import WarningIcon from "@mui/icons-material/Warning";

export default function Main() {
  const [groupRegistrations, setGroupRegistrations] = useState<any[]>([]);
  const [images, setImages] = useState<any>({});
  const [selectedGroupRegistration, setSelectedGroupRegistration] =
    useState<any>();

  useEffect(() => {
    reloadGroupRegistrations();
  }, []);

  function downloadImage(groupRegistration: any) {
    if (groupRegistration.url === null) return;

    var i = images;

    fetch(
      `https://files.gray-connect.de/${groupRegistration.number}%20/2020.JPG`
    )
      .then((res) => res.blob())
      .then((blob) =>
        blobToBase64(blob).then((blob: any) => {
          i[groupRegistration.id] = blob;

          setImages(i);
        })
      );
  }

  useEffect(() => {
    if (groupRegistrations.length === 0) return;

    //downloadImages()
  }, [groupRegistrations]);

  useEffect(() => {
    if (groupRegistrations.length === 0) return;
  }, [images, groupRegistrations]);

  const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  useEffect(() => {
    //setTitle(selectedGroupRegistration?.name || "")
  }, [selectedGroupRegistration]);

  const reloadGroupRegistrations = () => {
    const bearer = localStorage.getItem("token");

    if (bearer) {
      //setLoading(true)
      axios
        .get(Urls.GROUP_REGISTRATIONS, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: bearer,
          },
        })
        .then((res) => {
          setGroupRegistrations(res.data);
        })
        .catch((e) => {
          console.log("Unauthorized");
        });
    } else {
      console.log("No Bearer");
    }
  };

  const onCallback = (rating: any) => {
    setSelectedGroupRegistration((g: any) => {
      if (rating !== undefined) {
        setGroupRegistrations((groupRegistrations: any) => {
          groupRegistrations.find((gr: any) => gr.number === g.number).rating =
            rating;

          return groupRegistrations;
        });
      }

      return undefined;
    });
  };

  const _0Ref = useRef(null);
  const _50Ref = useRef(null);
  const _100Ref = useRef(null);
  const _150Ref = useRef(null);
  const _200Ref = useRef(null);

  // @ts-ignore
  const executeScroll = (ref: any) => ref?.current?.scrollIntoView();

  const renderGroupRegistration = (g: any) => {
    var ref = _0Ref;

    if (g.number === 50) {
      ref = _50Ref;
    } else if (g.number === 100) {
      ref = _100Ref;
    } else if (g.number === 150) {
      ref = _150Ref;
    } else if (g.number === 200) {
      ref = _200Ref;
    }

    return (
      <>
        {(g.number % 50 === 0 || g.number === 25) && (
          <Typography
            variant="h4"
            component="h1"
            sx={{ width: "100%", m: 2 }}
            ref={ref}>
            {g.number}
          </Typography>
        )}
        <Grid item xs={3} key={g.number}>
          <Button
            variant="contained"
            color={g.rating > 0 ? "success" : "warning"}
            size="large"
            sx={{ width: "100%", fontSize: 30 }}
            onClick={() => setSelectedGroupRegistration(g)}>
            {g.special_watch.toString() == "true" && (
              <WarningIcon sx={{ fontSize: 45 }} />
            )}
            <b>{g.number}</b>
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ p: 1 }} direction="row" spacing={2}>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            {!selectedGroupRegistration &&
              groupRegistrations.map((g) => renderGroupRegistration(g))}
          </Grid>
        </Grid>

        {!selectedGroupRegistration && (
          <Grid item xs={2} sx={{ position: "fixed", to: 70, right: 10 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color={"info"}
                  size="large"
                  sx={{ width: "100%", fontSize: 30 }}
                  onClick={() => {
                    executeScroll(_0Ref);
                  }}>
                  0-50
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color={"info"}
                  size="large"
                  sx={{ width: "100%", fontSize: 30 }}
                  onClick={() => {
                    executeScroll(_50Ref);
                  }}>
                  50-100
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color={"info"}
                  size="large"
                  sx={{ width: "100%", fontSize: 30 }}
                  onClick={() => {
                    executeScroll(_100Ref);
                  }}>
                  100-150
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color={"info"}
                  size="large"
                  sx={{ width: "100%", fontSize: 30 }}
                  onClick={() => {
                    executeScroll(_150Ref);
                  }}>
                  150-200
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color={"info"}
                  size="large"
                  sx={{ width: "100%", fontSize: 30 }}
                  onClick={() => {
                    executeScroll(_200Ref);
                  }}>
                  200-250
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {selectedGroupRegistration && (
          <GroupRegistration
            images={images}
            groupRegistration={selectedGroupRegistration}
            onCallback={onCallback}
          />
        )}
      </Grid>
    </>
  );
}
