import { useEffect, useRef, useState } from 'react';
import Main from './Main';
import Navbar from './Navbar';
import Presentation from './Presentation';
import Registration from './Registration';


function App() {
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<number>(0)
  const [title, setTitle] = useState<string>("")

  function togglePresentation(){
    setState(0)
  }
  
  
  return <>
    <Navbar name={title} loading={loading} state={state} setState={(s: number) => setState(s)} />
    { state === 0 && <Main />}
    { state === 1 && <Presentation />}
    { state === 2 && <Registration />}
  </>
}

export default App;
